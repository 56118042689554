<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Listings ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-app-bar>

    <div class="mt-12 mb-6">
      <v-row>
        <v-col cols="6" md="3">
          <v-text-field
            outlined
            clearable
            hide-details
            label="Search"
            @input="doFilter"
            placeholder="Enter name, phone or email"
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="3">
          <v-select
            v-model="selectedCategoryInDropdown"
            :items="categories"
            label="Select Category"
            item-value="id"
            item-text="label"
            multiple
            outlined
            @change="onSelectCategory"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggle">
                <v-list-item-action>
                  <v-icon
                    :color="
                      selectedCategoryInDropdown.length > 0
                        ? 'indigo darken-4'
                        : ''
                    "
                  >
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon> mdi-food-apple </v-icon>
                </v-list-item-avatar>

                <v-list-item-content v-if="likeAllCategories">
                  <v-list-item-title>
                    All items were selected.
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content v-else-if="likeSomeCategories">
                  <v-list-item-title> Category Count </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ selectedCategoryInDropdown.length }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content v-else>
                  <v-list-item-subtitle>
                    Go ahead, make a selection above!
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :items="listings"
      :loading="loading"
      :headers="headers"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="listMeta.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      v-model="selectedCategory"
    >
      <template v-slot:[`item.photos`]="{ item }">
        <v-img
          max-height="75"
          max-width="75"
          v-if="item.photos[0]"
          :src="item.photos[0].url"
        ></v-img>
      </template>
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[10, 20, 50, 100]"
        />
      </template>
      <template v-slot:[`item.categories`]="{ item }">
        {{ displayCategory(item.categories) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="identifyChipColor(item.status)" text-color="white">
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          depressed
          color="error"
          class="mx-1"
          @click="selectListingToDelete(item)"
        >
          Delete
        </v-btn>
      </template>
    </v-data-table>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Remove Listing"
      message="Are you sure you want to remove this listing?"
      @cancel="showDeleteModal = false"
      @confirm="deleteListingHandler"
      button-theme="error"
    ></ConfirmModal>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  mdiFileExcel,
  mdiPlus,
  mdiDownload,
  mdiCheck,
  mdiClose,
  mdiMinus,
  mdiCloseBox,
  mdiCheckboxBlankOutline,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import debounce from 'lodash/debounce'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ListingPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      options: {},
      loading: false,
      search: null,
      category_search: null,
      headers: [
        {
          text: 'Name',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Photo',
          sortable: false,
          value: 'photos',
        },
        {
          text: 'Owner',
          sortable: false,
          value: 'owner.full_name',
        },
        {
          text: 'Price',
          sortable: false,
          value: 'pricing',
        },
        {
          text: 'Category',
          sortable: false,
          value: 'categories',
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions',
          align: 'center',
        },
      ],
      filters: {
        account_status: null,
        has_other_meal_restrictions: null,
      },
      account_status_items: [
        { text: 'All', value: null },
        { text: 'Active', value: 'active' },
        { text: 'Onboarded', value: 'onboarded' },
        { text: 'Blocked', value: 'blocked' },
      ],
      icons: {
        export: mdiFileExcel,
        add: mdiPlus,
        minus: mdiMinus,
        closeBox: mdiCloseBox,
        download: mdiDownload,
        check: mdiCheck,
        close: mdiClose,
        boxBlank: mdiCheckboxBlankOutline,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      selectedCategory: [],
      listingToDelete: null,
      showDeleteModal: false,
      selectedCategoryInDropdown: [],
    }
  },

  computed: {
    ...mapState({
      listings: (state) => state.listing.list,
      listMeta: (state) => state.listing.listMeta,
      categories: (state) => state.category.list,
    }),
    likeAllCategories() {
      return this.selectedCategoryInDropdown.length === this.categories.length
    },
    likeSomeCategories() {
      return (
        this.selectedCategoryInDropdown.length > 0 && !this.likeAllCategories
      )
    },
    icon() {
      if (this.likeAllCategories) return this.icons.closeBox
      if (this.likeSomeCategories) return this.icons.minus
      return this.icons.boxBlank
    },
  },

  methods: {
    onSelectCategory(item) {
      this.selectedCategoryInDropdown = item
      this.getDataFromApi()
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likeAllCategories) {
          this.selectedCategoryInDropdown = []
          this.getDataFromApi(true)
        } else {
          this.selectedCategoryInDropdown = this.categories.slice()
          this.getDataFromApi(true)
        }
      })
    },
    identifyChipColor(status) {
      let color = 'green'
      if (status === 'expired') {
        color = 'red'
      } else if (status === 'closed') {
        color = 'orange'
      }
      return color
    },
    displayCategory(categories) {
      let categoryDisplay = []
      categories.map((category) => {
        categoryDisplay.push(category.label)
      })

      return categoryDisplay.join(',')
    },
    doFilter: debounce(function () {
      this.getDataFromApi()
    }, 600),

    async getDataFromApi(categoryMassSelect = false) {
      let categoryIds = this.selectedCategoryInDropdown
      if (categoryMassSelect) {
        categoryIds = this.selectedCategoryInDropdown
          .map((cat) => cat.id)
          .join(',')
      } else {
        categoryIds = this.selectedCategoryInDropdown.join(',')
      }
      this.loading = true
      this.$store.commit('listing/clearListings')
      await this.$store.dispatch('listing/getListing', {
        search: this.search,
        filters: this.filters,
        category_search: categoryIds,
        ...this.options,
      })
      this.loading = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    selectCategoryHandler(item) {
      this.category_search = item
      this.getDataFromApi()
    },

    selectListingToDelete(item) {
      this.listingToDelete = item
      this.showDeleteModal = true
    },

    async deleteListingHandler() {
      await this.$store.dispatch('listing/deleteCategory', this.listingToDelete)
      this.showDeleteModal = false
      this.showSnackbar('Listing successfully deleted.')
      this.listingToDelete = null
      this.getDataFromApi()
    },
  },
  async created() {
    this.$store.commit('category/clearCategoryList')
    await this.$store.dispatch('category/getCategories', { itemPerPage: 100 })
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(true)
      },
      deep: true,
    },
  },
}
</script>
